<template>
  <div :class="classes">
    <div id="klaviyo-form" :class="klaviyoFormClass" />
  </div>
</template>

<script>
import { getIntegrationConfigByType } from '@/legacy/helpers/integrationsHelper'

export default {
  name: 'ThirdPartyKlaviyoNewsletter',

  inject: ['currentLocaleConfig'],

  props: {
    formId: {
      type: String,
      required: true,
    },
  },

  computed: {
    classes() {
      return {
        ThirdPartyKlaviyoNewsletter: true,
      }
    },
    klaviyoFormClass() {
      return `klaviyo-form-${this.formId}`
    },
  },
}
</script>

<style lang="css">
#klaviyo-form form {
  max-width: 416px !important;
  padding: 0 !important;
  margin: 0 !important;
}

#klaviyo-form form > div > div:nth-child(1) {
  margin-bottom: 16px !important;
}

#klaviyo-form form > div > div:nth-child(1) p {
  font-family: 'Futura PT', sans-serif;
  font-size: 18px;
  line-height: 1.5556;
  font-weight: 700;
  letter-spacing: 0.02em;
  font-feature-settings: 'liga' 1;
}

#klaviyo-form p span {
  font-weight: inherit !important;
  color: inherit !important;
}

#klaviyo-form input {
  height: auto !important;
  font-family: 'Futura PT', sans-serif !important;
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 400 !important;
  letter-spacing: 0.02em !important;
  text-transform: none !important;
  padding: 17px 23px !important;
  padding-right: 95px !important;
  border: 1px solid rgb(0 0 0 / 20%) !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  caret-color: currentcolor !important;
}

#klaviyo-form [data-testid='form-component'] {
  padding: 0 !important;
}

#klaviyo-form button {
  position: absolute !important;
  right: 0 !important;
  height: 100% !important;
  font-family: 'Futura PT', sans-serif !important;
  font-size: 11px !important;
  line-height: 16px !important;
  font-weight: 700 !important;
  letter-spacing: 0.04em !important;
  text-transform: uppercase !important;
  padding: 0 23px !important;
  opacity: 0.5 !important;
  background: #fff !important;
  background-color: transparent !important;
  color: #232323 !important;
  font-feature-settings: 'liga' 1 !important;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1) !important;
  white-space: nowrap !important;
}

#klaviyo-form button:hover {
  opacity: 1 !important;
}

#klaviyo-form button:focus {
  box-shadow: none !important;
}

#klaviyo-form form > div > div:nth-child(3) {
  margin-top: 16px !important;
  color: #000;
}

@media (min-width: 769px) {
  #klaviyo-form input {
    font-size: 18px !important;
    line-height: 28px !important;
    padding-right: 103px !important;
    border-radius: 10px !important;
  }

  #klaviyo-form form > div > div:nth-child(1) p {
    font-size: 22px;
    line-height: 1.4545;
    letter-spacing: 0.02em;
  }
}
</style>
